import Pristine from 'pristinejs'

import { useNotifications } from '@utils/useNotifications'
import { Spinner } from '@components/Spinner'

const $document = $(document)
const createTeamForm = $('.create-team-form')
const Teams = {
  reloadLastTasksWidget: function () {
    if ($('.last-tasks').length) {
      $.ajax({
        url: Routing.generate('app_tasks_last_load'),
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          $('.last-tasks').html(data.html)
        },
      })
    }
  },
  invite: function (formData) {
    const self = this
    const $submitButton = $document.find('form[name=invitation]').find('button[type=submit]')

    $.ajax({
      url: Routing.generate('app_team_invite'),
      method: 'POST',
      dataType: 'json',
      data: formData,
      beforeSend: function () {
        Spinner($submitButton).prepend()
        self.disableButton($submitButton)
      },
      success: function (data) {
        if (data.success) {
          if (data.message !== undefined) {
            useNotifications('success', data.message)
          } else {
            useNotifications('success', Translator.trans('push_notification.team.invitation.sent'))
          }
          if ($('#invitation_email').length) {
            $('#invitation_email').val('')
          }
        } else {
          useNotifications('error', Translator.trans('push_notification.team.invitation.fail'))
        }
        Spinner($submitButton).remove()
        self.enableButton($submitButton)
      },
    })
  },
  select: function (teamId) {
    const params = {}
    if (typeof moment !== 'undefined') {
      params.endedAt = moment().format('YYYY-MM-DD H:mm:ss')
    }

    $.ajax({
      url: Routing.generate('app_team_select', { id: teamId }),
      method: 'POST',
      data: params,
      dataType: 'json',
      success: function (data) {
        if (data.success) {
          window.location.reload()
        }
      },
    })
  },
  getUsers: function () {
    $.ajax({
      url: Routing.generate('app_team_users'),
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        if (data.success && typeof data.html !== 'undefined' && $('#modal-team-users').length) {
          $('#modal-team-users .modal-body').html(data.html)
          $('#modal-team-users').modal('show')
          $('.select2').select2()
        }
      },
    })
  },
  setUserPermissions: function (userId, roles) {
    $.post(Routing.generate('app_team_user_roles', { userId: userId, roles: roles }))
  },
  loadCurrentTask: function () {
    $.ajax({
      url: Routing.generate('app_team_task_active'),
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        if (data.success && typeof data.html !== 'undefined') {
          if ($('.current-task').length) {
            $('.current-task').html(data.html)
          }

          if ($('.current-task .total-time').length) {
            taskStarted = $('.current-task .total-time').data('startedAt')
          }

          if (data.taskStarted) {
            taskStarted = data.taskStarted
          }

          if (typeof TimeEdTimer !== 'undefined' && typeof taskStarted !== 'undefined' && taskStarted) {
            const TEdTimer = new TimeEdTimer({
              timeStr: taskStarted,
              //setTotalTimeSelector: ".task-timer-box .total-time"
              setTotalTimeSelector: '.started-task-box .task-spend-time',
            })

            TEdTimer.run()
          }
        }
      },
    })
  },
  loadUserReport: function (userId) {
    $.ajax({
      url: Routing.generate('app_user_tasks_time', { userId: userId }),
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        if (data.success && typeof data.html !== 'undefined' && $('#modal-team-user-report')) {
          $('#modal-team-user-report .modal-body').html(data.html)
          $('#modal-team-user-report').modal('show')
        }
      },
    })
  },
  updateReportUsers: function (formData) {
    $.ajax({
      url: Routing.generate('app_team_report_update'),
      method: 'POST',
      dataType: 'json',
      data: formData,
      success: function (data) {
        if (data.success && data.html) {
          if ($('.subscription-list').length) {
            $('.subscription-list').html(data.html)
            $('.select2').select2()
          }
        }
      },
    })
  },
  toggleUserState: function (userId, enabled) {
    $.post(Routing.generate('app_team_user_status', { userId: userId }), { enabled: enabled })
  },

  toggleUserRole: function (data, userId) {
    const $changeStatusRoleSelect = $('.change_status_role')

    $.ajax({
      url: Routing.generate('app_team_update_user_role', { userId: userId }),
      method: 'POST',
      dataType: 'json',
      data: data,
      success: function (response) {
        if (response.success) {
          useNotifications('success', Translator.trans('push_notification.team.status.success'))
        } else {
          useNotifications('error', response.message)
        }
      },
      error: function (err) {
        if (err.status === 500) {
          useNotifications('error', Translator.trans('push_notification.team.status.teamIdIsMissing'))
        }
      },
    }).always(() => {
      $changeStatusRoleSelect.parent().toggleClass('is-loading', false)
      Spinner($changeStatusRoleSelect.parent()).remove()
    })
  },

  loadTeamOptions: function (teamId) {
    const self = this
    if (!teamId) return
    $.ajax({
      url: Routing.generate('app_team_options', { teamId: teamId }),
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        if (data.success && typeof data.html !== 'undefined' && $('#team-switcher')) {
          $('#content-box').html(data.html)
          $('#team-switcher').select2()

          $('.change_status_role').select2({
            minimumResultsForSearch: 4,
            width: '100%',
          })

          $('.change_status_role').on('change', function (e) {
            $(this).parent().toggleClass('is-loading')
            Spinner($(this).parent()).prepend()
            Teams.toggleUserRole($(this).closest('form').serializeArray(), $(this).data('user-id'))
          })
          self.initInviteForm()
        }
      },
    })
  },

  initInviteForm: function () {
    const invitationForm = $document.find('form[name=invitation]')
    let pristine = null

    if (invitationForm.length) {
      pristine = new Pristine(invitationForm.get(0))
    }

    invitationForm.on('submit', (e) => {
      e.preventDefault()

      if (pristine && pristine.validate()) {
        this.invite(invitationForm.serialize())
      }
    })
  },

  disableButton: function (buttonElement) {
    buttonElement.prop('disabled', true)
  },

  enableButton: function (buttonElement) {
    buttonElement.prop('disabled', false)
  },
}

// Declare object/function/variables/constants that can be imported and used in other js files
export { Teams }
